<template>
<nav id="navbar" class="navbar sticky-top navbar-expand-lg  blue" :class="{ 'onScroll': !view.topOfPage}">
  <div  class="d-flex flex-grow-1 align-items-center">
    <div class="ms-4">
      <img src="@/assets/img/logo-version-borde-2r.png" width="180" alt="Work Mileage">
    </div>
      <div class="w-100 text-right" align="right">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"><i class="fa-solid fa-bars"></i></span>
        </button>
      </div>
     </div>
    <div class="collapse navbar-collapse flex-grow-1 text-right me-5 px-4" id="navbarNavAltMarkup">
      <ul class="navbar-nav ms-auto flex-nowrap">
          <li class="nav-item nav-link" @click="goto('usecase')">
            Use Case
          </li>
           <li class="nav-item nav-link" @click="goto('workmileage')">
              Workmileage
           </li>
           <li class="nav-item nav-link" @click="goto('features')">
              Features
           </li>
           <li class="nav-item nav-link" @click="goto('about')">
              About
           </li>
            <li class="nav-item nav-link" @click="goto('pricing')">
              Pricing
            </li>
            <!-- <li class="nav-item nav-link" @click="goto('download')">
               Download
            </li> -->
            <li class="nav-item nav-link" @click="goto('contact')">
              Contact
            </li>
            <li  @click="redirect('https://www.app.workmileage.com/signup')" class="trial-button nav-item nav-link">
              Start your 30 day trial
            </li>
            <li @click="redirect('https://www.app.workmileage.com')" class="login-button nav-item nav-link">
              Login
            </li>
      </ul>
      </div>
</nav>
</template>
<script>
/* eslint-disable */
export default {
  setup () {
  },
  el: '#navbar',
  data () {
    return {
      view: {
        topOfPage: true
      }
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true
      }
    },
    goto (refName) {
      const position = document.getElementById(refName).offsetTop
      window.scrollTo({ top: position, behavior: 'smooth' })
    },
    redirect: function (url) 
    {
      window.location = url
    }
  }
}
</script>
<style scoped>
/* nav scroll */
.nav-link{
  color: #fff;
  font-size: medium;
  margin: 10px;
  cursor:pointer;
}
.trial-button{
  padding: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #EA7826;
  padding-top:7px;
}
.login-button{
  background-color: #EA7826;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  padding-top:7px;
}
body nav.onScroll {
    box-shadow: 0 0 10px #aaa;
    background-color: #fff;
  }
body nav.onScroll .nav-link{
    color: #EA7826;
    text-decoration: none !important;
}
body nav.onScroll .trial-button{
    color: #FFFFFF;
    background-color:#15588E;
    border: 2px solid #15588E;
    text-decoration: none !important;
}
body nav.onScroll .login-button{
    color: #15588E;
    background-color:#FFF;
    border: 2px solid #15588E;
    text-decoration: none !important;
}
body nav.onScroll .nav-link:hover{
    color: #EA7826;
    text-decoration: none !important;
    background-color: #FFFFFF !important;
    cursor:pointer;
}
body nav.onScroll .navbar-toggler-icon{
    color: #EA7826;
    text-decoration: none !important;
}
body nav.onScroll .navbar-brand{
    color: #EA7826;
    text-decoration: none !important;
}
.navbar-toggler-icon{
  color:#fff;
}
.navbar-brand{
  color: #fff;
}
/* end */
</style>
