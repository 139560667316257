<template>
    <section id="contact" ref="contact" class="ancla">
        <div class="d-flex align-items-center blue">
        <div class="container">
            <div class="row mt-5">
                <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
                    <h1 class="display-6 text-white">Contact</h1>
                    <h4 class="text-white fw-light">Get in touch</h4>
                </div>
                <div class="col-lg-12 col-xs-12 col-sm-12 d-flex align-items-center justify-content-center">
                <div class="p-4 w-50 mb-5 shadow-lg bg-light rounded">
                 <form @submit.prevent="SendMail()" method="POST" class="mt-8 space-y-6">
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label muted">Full Name</label>
                            <input v-model="Post.name" type="text" class="form-control" id="name" placeholder="John Doe" required="required">
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Email address</label>
                            <input type="email" v-model="Post.email"  class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea v-model="Post.message"  class="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
                        </div>
                        <div align="center">
                            <vue-recaptcha ref="Recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="sitekey"> </vue-recaptcha>
                            <button :disabled="Form.submit" type="submit" class="button-blue btn btn-lg mt-2">Send</button>
                        </div>
                        <div v-if="Form.msg != ''" class="mb-3">{{Form.msg}}</div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    </div>
    </section>
</template>
<script>
/* eslint-disable */
import Api from '@/services/Api';
import { computed, defineComponent } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default defineComponent
({
    components: 
    {
        VueRecaptcha
    },  
    data: () => (
    {
        sitekey:'6LfjOusgAAAAAFZPl3InvYkH2rlZnIYN9qOJ5YeV',
        Form:
        {
         submit: true,
         msg:''
        },
        Post:
        {
            name:'',
            email:'',
            message:'',
            code:'',
        }
  
    }),
    methods:
    {
        onSubmit: function () 
        {
            this.$refs.Recaptcha.execute()
        },
        onVerify: function (response) 
        {
            //console.log('Verify: ' + response)
            this.Form.submit = false
            this.Post.code = response

        },
        onExpired: function () 
        {
            console.log('Expired')
        },
        resetRecaptcha() 
        {
            this.$refs.recaptcha.reset() // Direct call reset method
        },

        SendMail()
        {

            Api.post("/form/contact",this.Post).then((response) => 
            {

                if(response.data.status == "true")
                {
                    this.Form.msg = response.data.msg

                }

                if(response.data.status == "false")
                {
                    this.Form.msg = response.data.msg
                }
            })
        }
        
    },
    mounted()
    {
    
    }
})
</script>
