<template>
    <section class="ancla my-1 py-5 bg-light" id="about" ref="about">
        <div class="curve-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FFFFFF" fill-opacity="1" d="M0,256L120,234.7C240,213,480,171,720,176C960,181,1200,235,1320,261.3L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
        </div>
        <div class="container my-5 py-5">
           <div class="row">
               <div class="col-md-12 col-sm-12 col-xs-12">
                   <h3 class="display-6 text-orange text-center">About us</h3>
                   <h4 class="text-muted text-justify fw-light">
                      For almost 15 years we have pioneered on working with remote teams and created a comprehensive productivity model .   Along all these years we have enabled multiple teams to reach success by coaching and providing direction based on insights generated from productivity patterns.
                    </h4>
                </div>
           </div>
        </div>
        <div class="curve-down">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FFFFFF" fill-opacity="1" d="M0,256L120,234.7C240,213,480,171,720,176C960,181,1200,235,1320,261.3L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
        </div>
    </section>
</template>
<style>
.curve-top{
    display: block;
    margin-top: -8rem;
}
.curve-down{
    display: block;
    margin-bottom: -4rem;
}
@media (max-width: 575.98px) {
.curve-top{
    display: block;
    margin-top:-3rem;
}
.curve-down{
    display: block;
    margin-bottom: -3rem !important;
}
}
</style>
