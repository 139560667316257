<template>
    <section class="gray ancla" id="download" ref="download">
        <div class="container">
           <div class="row justify-content-center" align="center">
               <div class="col-md-12 col-sm-12 col-xs-12 my-5">
                   <h3 class="display-6 text-orange text-center "><b>Manage your team’s productivity,<br> simple and at the most affordable cost</b></h3>
                   <a href="https://wmappserver.github.io/setup.exe" class="btn btn-primary btn-download btn-lg my-4">Download for Windows</a>
                </div>
           </div>
        </div>
    </section>
</template>
<style>
.btn-download{
  color: #FFFFFF;
  background-color: #15588E;
  border: 2px solid #15588E;
  text-decoration: none !important;
}
.btn-download:hover{
  color: #FFFFFF;
  background-color: #15588E;
  border: 2px solid #15588E;
  text-decoration: none !important;
}
</style>
