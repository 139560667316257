<template>
   <section id="usecase" class="ancla" ref="usecase">
        <div class="container my-5">
        <div class="row justify-content-center">
            <div class="my-5">
                <h6 class="display-6 text-blue text-center">Common use cases we support</h6>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-12 text-center">
                <i class="icon-color-blue fa-solid fa-list-check fa-2x"></i>
                <div class="my-2">
                    <p class="text-muted">Track the work that your team is allocating to different projects.</p>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-12 text-center">
               <i class="icon-color-blue fa-solid fa-arrow-up-short-wide fa-2x"></i>
               <div class="my-2">
                   <p class="text-muted">Correlate apps and websites to productivity profiles.</p>
               </div>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-12 text-center">
               <i class="icon-color-blue fa-solid fa-business-time fa-2x"></i>
               <div class="my-2">
                   <p class="text-muted">Time tracking so you can bill for consulting and professional services.</p>
               </div>
            </div>
        </div>
        <div class="row my-5 justify-content-around">
            <div class="col-md-4 col-xs-12 col-sm-12 text-center">
                <i class="icon-color-blue fa-solid fa-bars-progress fa-2x"></i>
                <div class="my-2">
                  <p class="text-muted">Understand productivity patterns.</p>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-12 text-center">
                <i class="icon-color-blue fa-solid fa-money-bill fa-2x"></i>
                <div class="my-2">
                   <p class="text-muted"> Generate data to pay team members.</p>
                </div>
            </div>
        </div>
    </div>
   </section>
</template>
