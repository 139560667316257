<template>
 <div>
    <agile :initial-slide="0" :fade="true" :autoplay="true" :infinite="true" :autoplay-speed="3000" :mobileFirst="true" :speed="300">
      <img
        class="slide"
        src="@/assets/img/carrusel1.png"
      >
      <img
        class="slide"
        src="@/assets/img/carrusel2.png"
      >
      <img
        class="slide"
        src="@/assets/img/carrusel3.png"
      >
      <template v-slot:prevButton><i class="prev fa-solid fa-chevron-left"></i></template>
      <template v-slot:nextButton><i class="next fa-solid fa-chevron-right"></i></template>
    </agile>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
export default {
  components: {
    agile: VueAgile
  },
  data () {
    return {
      myOptions: {
        navButtons: true,
        dots: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false
            }
          },
          {
            breakpoint: 900,
            settings: {
              arrows: true,
              dots: true,
              infinite: false
            }
          }
        ]
      }
    }
  }
}
</script>
<style>
.agile__nav-button {
  background: transparent;
  border: none;
  color: #15588e;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px; }
.agile__dot button {
  background-color: #EA7826;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: .3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #888;
}

.agile__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
</style>
