<template>
    <section class="my-5 py-5 ancla" id="features" ref="features">
        <div class="container-lg">
           <div class="row align-items-center justify-content-between">
               <div class="col-md-5 col-lg-6 order-md-1">
                    <div class="position-relative mb-8 mb-md-2">
                      <img class="position-relative img-fluid rounded shadow-lg mb-2 mt-4" src="@/assets/img/image3.png">
                    </div>
               </div>
               <div class="col-md-6 col-lg-5 order-md-0">
                   <h3 class="display-6 text-orange">Project time allocation</h3>
                   <p class="text-muted lead">Working on different projects simultaneously? Keep track of how you are allocating the times and activities into a given project.</p>
                </div>
               </div>
               <div class="row align-items-center justify-content-center my-4 ">
                    <div class="col-lg-4 col-sm-12 col-xs-12 my-1">
                        <img src="@/assets/img/img3.jpg" class="position-relative img-fluid rounded shadow-sm" alt="Workmileage Remote">
                    </div>
                     <div class="col-lg-4 col-sm-12 col-xs-12 my-1">
                        <img src="@/assets/img/img4.webp" class="position-relative img-fluid rounded shadow-sm" alt="Workmileage">
                    </div>
                     <div class="col-lg-4 col-sm-12 col-xs-12 my-1">
                        <img src="@/assets/img/img2.webp" class="position-relative img-fluid rounded shadow-sm" alt="Workmileage">
                    </div>
               </div>
        </div>
    </section>
</template>
