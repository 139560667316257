<template>
    <section class="ancla" id="pricing" ref="pricing">
        <div class="container">
            <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 class="display-6 text-orange">Pricing</h1>
            </div>
            <div class="">
                <div class="d-flex justify-content-center row row-cols-1 row-cols-md-2 row-cols-xs-1 mb-3">
                    <div class="col w-card">
                        <div class="card mb-4 rounded-3 shadow-sm">
                        <div class="card-header py-3">
                            <h1 class="text-center text-blue card-title pricing-card-title">$3<small class="text-blue fw-light">/month</small></h1>
                        </div>
                        <div class="card-body">
                            <h4 class="text-muted text-center my-0 fw-normal">Basic</h4>
                            <ul class="list-unstyled mt-3 mb-4 text-left p-3">
                                <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Time Tracking</li>
                                <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Activity Levels</li>
                                <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Unlimited Screenshots</li>
                                <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Unlimited Payments</li>
                            </ul>
                            <button @click="redirect('https://www.app.workmileage.com/signup')" type="button" class="button-blue w-100 btn btn-lg btn-primary text-center">Get started</button>
                        </div>
                        </div>
                    </div>
                    <div class="col w-card">
                        <div class="card mb-4 rounded-3 shadow-sm border-primary">
                        <div class="card-header-blue card-header py-3 text-white border-primary">
                            <h1 class="card-title pricing-card-title text-center">$4<small class="text-white fw-light">/month</small></h1>
                        </div>
                        <div class="card-body">
                            <h4 class="text-muted text-center my-0 fw-normal">Pro</h4>
                            <ul class="list-unstyled mt-3 mb-4 text-left p-3">
                             <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Time Tracking</li>
                             <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Activity Levels</li>
                             <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Unlimited Screenshots</li>
                             <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Unlimited Payments</li>
                             <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Track URLs</li>
                             <li><i class="icon-color-blue-2 fa-solid fa-circle-check"></i> Track Apps</li>
                            </ul>
                            <button @click="redirect('https://www.app.workmileage.com/signup')" type="button" class="button-blue w-100 btn btn-lg btn-primary">Get started</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* eslint-disable */
export default 
{
  setup () 
  {
  },
  methods: 
  {
    redirect: function (url) 
    {
      window.location = url
    }
  }
}
</script>
<style>
.w-card{
    width: 300px;
}
.icon-color-blue-2{
    color: #15588E;
}
</style>
