<template>
    <section class="ancla">
        <div class="container-lg">
           <div class="row  align-items-center justify-content-between">
                <div class="col-md-4 col-sm-12 col-xs-12 my-3 order-sm-1 d-none d-sm-block d-sm-none d-md-block">
                    <div class="position-relative mb-8 mb-md-0">
                        <img src="@/assets/img/img2.png" width="300" class="position-relative img-fluid rounded shadow-lg" alt="">
                    </div>
               </div>
               <div class="col-md-8 col-sm-12 col-xs-12 align-items-center order-sm-0">
                   <h3 class="display-6 text-orange my-2">Productivity insights</h3>
                   <p class="text-muted">
                       It’s eye opening when you really realize how in reality you are spending your time.
                       Workmileage provides great insight on your team’s productivity.
                       Understand what apps and sites are most comply used and how they correlate with the productivity of your team.
                   </p>
                   <h3 class="display-6 text-orange  my-4">Integrations</h3>
                   <p class="text-muted">
                        Let's get better together. We are just starting and developing new integrations. Once you are onboard with us, let us know which productivity integration you would
                        like to have and we will implement it at no cost.
                    </p>
                    <div class="row">
                       <div class="col-3">
                            <img src="@/assets/img/worked.png" class="position-relative img-fluid rounded shadow" width="150" alt="Workmileage">
                       </div>
                        <div class="col-3"><img src="@/assets/img/activity.png" class="position-relative img-fluid rounded shadow" width="150" alt=""></div>
                        <div class="col-3"><img src="@/assets/img/cost.png" class="position-relative img-fluid rounded shadow" width="150" alt=""></div>
                        <div class="col-3"><img src="@/assets/img/project.png" class="position-relative img-fluid rounded shadow" width="150" alt=""></div>
                    </div>
                </div>
           </div>
        </div>
    </section>
</template>
