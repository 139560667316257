<template>
   <HeaderContent></HeaderContent>
   <CarouselContent></CarouselContent>
   <SectionOneContent></SectionOneContent>
   <SectionTwoContent></SectionTwoContent>
   <SectionThreeContent></SectionThreeContent>
   <SectionFourContent></SectionFourContent>
   <SectionFiveContent></SectionFiveContent>
   <PricingContent></PricingContent>
   <ContactContent></ContactContent>
   <FooterContent></FooterContent>
</template>
<script>
/* eslint-disable */
import HeaderContent from '@/components/HeaderContent.vue'
import CarouselContent from '@/components/CarouselContent.vue'
import SectionOneContent from '@/components/SectionOneContent.vue'
import SectionTwoContent from '@/components/SectionTwoContent.vue'
import SectionThreeContent from '@/components/SectionThreeContent.vue'
import SectionFourContent from '@/components/SectionFourContent.vue'
import SectionFiveContent from '@/components/SectionFiveContent.vue'
import SectionSixContent from '@/components/SectionSixContent.vue'
import PricingContent from '@/components/PricingContent.vue'
import ContactContent from '@/components/ContactContent.vue'
import FooterContent from '@/components/FooterContent.vue'
export default {
  name: 'HomeView',
  components: {
    HeaderContent,
    CarouselContent,
    SectionOneContent,
    SectionTwoContent,
    SectionThreeContent,
    SectionFourContent,
    SectionFiveContent,
    SectionSixContent,
    PricingContent,
    ContactContent,
    FooterContent
  }
}
</script>
