<template>
    <div class="container-fluid p-4" align="center">
        <footer class="d-flex justify-content-center align-items-center">
            <div class="col-md-12 d-flex align-items-center">
                <a href="/" class="mb-3 me-2 mb-md-0 text-blue text-decoration-none lh-1">
                    <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
                </a>
                <span class="text-blue">© 2022 Workmileage, All rights reserved.</span>
            </div>
            <!--
            <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li class="ms-3"><i class="fa-brands fa-twitter text-blue"></i></li>
            <li class="ms-3"><i class="fa-brands fa-instagram text-blue"></i></li>
            <li class="ms-3"><i class="fa-brands fa-facebook text-blue"></i></li>
            </ul>-->
        </footer>
    </div>
</template>
