/* eslint-disable */
import axios from 'axios';

const Api  = axios.create ({
  baseURL: process.env.VUE_APP_ENDPOINT,
  headers: 
  { 
    "Content-Type": "application/json",

  }
})

export default Api