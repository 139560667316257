<template>
    <section class="blue py-3 ancla" id="workmileage" ref="workmileage">
        <div class="curve">
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#FFFFFF" fill-opacity="1" d="M0,224L48,224C96,224,192,224,288,197.3C384,171,480,117,576,80C672,43,768,21,864,48C960,75,1056,149,1152,165.3C1248,181,1344,139,1392,117.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
        </div>
          <div class="container">
            <div class="row align-items-center justify-content-between pb-5">
                <div class="mt-0 mb-4">
                    <h4 class="display-6 text-center text-white">Reach the highest productivity!</h4>
                </div>
                <div class="col-md-6 col-xs-12 col-sm-12 d-flex justify-content-center order-md-1">
                    <div class="position-relative mb-8 mb-md-0 mb-sm-4">
                      <img src="@/assets/img/img1.png" class="fade-in position-relative img-fluid rounded shadow-lg" width="300" alt="">
                    </div>
                </div>
                <div class="col-md-6 col-xs-12 col-sm-12 order-md-0">
                    <div class="mt-4">
                        <p class="text-white text-justify lead">
                        <i>Workmileage is the simplest productivity tracking tool for remote work.</i>
                        </p>
                        <p class="text-white text-justify lead">
                        "No key loggers,  no webcam trackers, instead Workmileage provides great insight on what
                        just matters “How you spend your and your team’s valuable time”.
                        </p>
                    </div>
                    <div>
                        <p class="text-white lead">Ensure your time allocation is aligned with your goals.</p>
                    </div>
                    <div>
                        <p class="text-white lead">Best cost-benefit in the market.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="curve-down">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,224L48,224C96,224,192,224,288,197.3C384,171,480,117,576,80C672,43,768,21,864,48C960,75,1056,149,1152,165.3C1248,181,1344,139,1392,117.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        </div>
   </section>
</template>
<style>
.curve{
    display: block;
    margin-top:-2rem;
}
.curve-down{
    display: block;
    margin-bottom: -7rem;
}
@media (max-width: 575.98px) {
.curve{
    display: block;
    margin-top:-1rem;
}
.curve-down{
    display: block;
    margin-bottom: -2rem !important;
}
}
</style>
